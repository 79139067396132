import '@babel/polyfill'
import { createApp } from 'vue'
import App from './App.vue'
import { Swipe, SwipeItem } from 'vant';
import { Icon } from 'vant';
import { Overlay } from 'vant';

const app = createApp(App);
app.use(Swipe);
app.use(SwipeItem);
app.use(Icon);
app.use(Overlay);

app.mount('#app')
