export default {
  name: "QuestionRandomizer",
  props: {
    question: {
      type: String
    },
    imageUrl: {
      type: String
    },
    linkPrefix: {
      type: String
    },
    items: {
      type: Array,
      Object
    }
  },

  data() {
    return {
      drawtime: 3000,
      currentDrawtime: 0,
      disableButton: false
    };
  },

  methods: {
    iterateItems(i = 0) {
      if (i >= this.items.length - 1) {
        return 0;
      }

      return i;
    },

    cycleItems() {
      const cyclingTime = 100;
      this.$emit("showDisplay");
      this.$emit("cycling", true);
      this.disableButton = true;
      let index = 0;
      let cyclingInterval = setInterval(() => {
        this.currentDrawtime += cyclingTime;
        let addIndex = Math.floor(Math.random() * (this.items.length / 2));
        index = this.iterateItems(index + addIndex + 1);
        this.$emit("displayItem", {
          name: this.items[index],
          prefix: ""
        });

        if (this.currentDrawtime >= this.drawtime) {
          this.currentDrawtime = 0;
          index = Math.floor(Math.random() * this.items.length);
          this.$emit("displayItem", {
            name: this.items[index],
            prefix: this.linkPrefix
          });
          clearInterval(cyclingInterval);
          this.disableButton = false;
          this.$emit("showAnimation");
          this.$emit("cycling", false);
        }
      }, cyclingTime);
    }

  }
};