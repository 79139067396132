import QuestionRandomizer from "./components/QuestionRandomizer.vue";
import "vant/es/swipe/style";
import "vant/es/icon/style";
import "vant/es/overlay/style";
const QUESTION_STORAGE = "questionStorage";
const localStorage = window.localStorage;
export default {
  name: "App",
  components: {
    QuestionRandomizer
  },

  created() {
    this.getFilteredQuestions();
  },

  data() {
    return {
      displayedItem: "&nbsp",
      showDisplay: false,
      showAnimation: false,
      swipedToHash: false,
      stayShown: false,
      linkPrefix: "",
      showInfo: false,
      questions: [{
        prompt: "Hol egyen ma",
        imageUrl: require("@/assets/dori_es_janos.png"),
        filename: "Ettermek",
        linkprefix: "//www.google.com/maps/search/",
        render: false
      }, {
        prompt: "Hol kávézzon ma",
        imageUrl: require("@/assets/dori_es_janos.png"),
        filename: "Kavezok",
        linkprefix: "//www.google.com/maps/search/",
        render: false
      }, {
        prompt: "Milyen kávét rendeljen",
        imageUrl: require("@/assets/csak_janos.png"),
        filename: "Kavek",
        linkprefix: "//www.google.com/search?q=",
        render: false
      }, {
        prompt: "Hova utazzon",
        imageUrl: require("@/assets/dori_es_janos.png"),
        filename: "Orszagok",
        linkprefix: "//www.google.com/maps/search/",
        render: false
      }, {
        prompt: "Milyen kaját rendeljen",
        imageUrl: require("@/assets/dori_es_janos.png"),
        filename: "Rendeles",
        linkprefix: "https://wolt.com/hu/search?q=",
        render: false
      }, {
        prompt: "Milyen autót vegyen",
        imageUrl: require("@/assets/dori_es_janos.png"),
        filename: "Autok",
        linkprefix: "//www.google.com/search?q=hasznalt%20",
        render: false
      }]
    };
  },

  computed: {
    filteredQuestions() {
      const hash = location.hash.replace(/^#/, "");
      let questions = this.questions;

      if (hash && hash.length) {
        questions = this.questions.filter(q => q.filename.match(hash));

        if (questions && questions.length) {
          return questions;
        }
      }

      return this.questions.filter(q => q.render);
    },

    origin() {
      return location.origin;
    }

  },
  methods: {
    next() {
      this.$refs.swiper.next();
    },

    prev() {
      this.$refs.swiper.prev();
    },

    displayItem(e) {
      this.displayedItem = e.name;
      this.linkPrefix = e.prefix;
    },

    closeDisplay() {
      if (!this.stayShown) {
        this.showDisplay = false;
        this.showAnimation = false;
      }
    },

    setStayShown(e) {
      this.stayShown = e;
    },

    async getFilteredQuestions() {
      this.fetchQuestions().then(() => {
        const jsonString = JSON.stringify(this.questions);
        localStorage.setItem(QUESTION_STORAGE, jsonString);
      }).catch(err => {
        const jsonString = localStorage.getItem(QUESTION_STORAGE);

        if (jsonString) {
          this.questions = JSON.parse(jsonString);
        }

        console.log(err);
      });
    },

    async fetchQuestions() {
      return fetch("https://api.github.com/gists/61ddd2a5003cc84aef1c169e89bf399d").then(response => response.json().then(data => {
        if (response.ok) {
          this.filterQuestions(Object.values(data.files));
        }
      }));
    },

    filterQuestions(gistFiles) {
      this.questions.forEach(q => {
        let file = gistFiles.find(item => item.filename == q.filename);

        if (file && file.content) {
          let items = file.content.split("\n");

          if (items && items.length) {
            q.items = items;
            q.render = true;
          }
        }
      });
    },

    getQuestionURL(question) {
      return location.origin + '/#' + question.filename;
    },

    goToQuestionURL(question) {
      location.hash = "#" + question.filename;
      open(this.getQuestionURL(question)); //window.location.reload(true);
    }

  }
};