import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-492c3ef2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "question-container"
};
const _hoisted_2 = {
  class: "question-title"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "start-container"
};
const _hoisted_5 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h1", _hoisted_2, _toDisplayString($props.question), 1), _createElementVNode("img", {
    class: "dj-logo",
    alt: "",
    src: $props.imageUrl
  }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("button", {
    class: "start-button",
    onClick: _cache[0] || (_cache[0] = $event => $options.cycleItems()),
    disabled: $data.disableButton,
    ref: "startButton"
  }, " ? ", 8, _hoisted_5)])]);
}